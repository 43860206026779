import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { Box, Container, Grid, useTheme, useMediaQuery } from '@mui/material'

import Card from 'components/molecules/Card'
import Layout from 'components/organisms/global/Layout'
import TextHeroCurve from 'components/organisms/hero/TextHeroCurve'
import SunBoxContainer from 'components/atoms/SunBoxContainer'
import TextAndImage from 'components/molecules/textAndImage/TextAndImage'
import SocialButtonLinks from 'components/molecules/SocialButtonLinks'

// @ts-ignore
import { DatoCmsSupporter, StoryQuery } from '__generated__/gatsby-types'

interface Props {
  data: StoryQuery
}

const Supporter = ({ data }: Props) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const d = data.datoCmsAutomatedLanding
  return (
    <Layout metadata={{ seoTags: d.seoMetaTags }} forceSolidNav>
      <TextHeroCurve
        heading={d.pageTitleNode.childMarkdownRemark.html}
        text={d.text}
        keyId={d.originalId}
        maskColor={{ xs: 'lightGrey', md: 'white' }}
      />

      <SunBoxContainer
        component="section"
        bgcolor={{ xs: 'lightGrey', md: 'white' }}
        py={{ xs: 6, sm: 7, md: 8, lg: 9, xl: 10 }}
        pt={{ xs: 0, md: 8, lg: 9, xl: 10 }}
      >
        <Container maxWidth="xl">
          {mdDown ? (
            <Grid container spacing={3} alignItems="stretch" sx={{ mt: 3 }}>
              {data.supporters.edges.map((item: any) => {
                const s: DatoCmsSupporter = item.node
                return (
                  <Grid
                    item
                    md={6}
                    xl={4}
                    key={s.originalId}
                    display="flex"
                    alignItems="stretch"
                  >
                    <Card
                      heading={s.name}
                      subHeading={s.supporterTypeLink.supporterType}
                      apiKey={s.model.apiKey}
                      text={s.about}
                      image={s.heroImage.gatsbyImageData}
                      imageAlt={s.heroImage.alt}
                      socialMedia={s.socialMedia}
                      dateLayout
                    />
                  </Grid>
                )
              })}
            </Grid>
          ) : (
            <>
              {data.supporters.edges.map((item: any, index: number) => {
                const s: DatoCmsSupporter = item.node
                return (
                  <Box
                    key={`supporter-${s.originalId}`}
                    mt={index > 0 ? { xs: 0, md: 4, lg: 8 } : 0}
                    mb={
                      index === data.supporters.length - 1
                        ? { xs: 0, lg: 8, xl: 10 }
                        : 0
                    }
                  >
                    <TextAndImage
                      layout="content-panel-small-pic"
                      keyId={s.originalId}
                      image={s.imagePortrait.gatsbyImageData}
                      imageLandscape={s.imageLandscape.gatsbyImageData}
                      heading={s.name}
                      subHeading={s.supporterTypeLink.supporterType}
                      text={s.aboutNode.childMarkdownRemark.html}
                      panelBgColor="lightGrey"
                      postText={
                        <SocialButtonLinks
                          socialButtons={s.socialMedia}
                          variant="border-dark strong"
                          boxProps={{
                            mt: index > 0 ? 4 : 0,
                          }}
                        />
                      }
                    />
                  </Box>
                )
              })}
            </>
          )}
        </Container>
      </SunBoxContainer>
    </Layout>
  )
}

export default memo(Supporter)

export const pageQuery = graphql`
  query SupporterQuery($originalId: String!, $skip: Int!, $perPage: Int!) {
    datoCmsAutomatedLanding(originalId: { eq: $originalId }) {
      originalId
      pageTitleNode {
        childMarkdownRemark {
          html
        }
      }
      text
      summaryItemName
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    supporters: allDatoCmsSupporter(
      limit: $perPage
      skip: $skip
      sort: { fields: [supporterTypeLink___position, name], order: [ASC, ASC] }
    ) {
      edges {
        node {
          ...supporterCard
        }
      }
    }
  }
`
